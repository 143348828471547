html,
body {
	height: 100vh;
}
body {
	display: flex;
	flex-direction: column;
}
hr {
	border-width: 3px;
	/* PS */
	border: 1px solid #009fe3;
}
.content {
	flex: 1 0 auto;
}
.footer {
	flex-shrink: 0;
	padding: 20px 0;
}
h1 {
	/* PS */
	font-size: 1.25rem;
}
/* PS */
h2 {
	font-size: 1.15rem;
}
h3 {
	/* PS */
	font-size: 1.08rem;
}
#suspendUserForm .form-check {
	margin-left: 2%;
}
a.blueLink:link,
a.blueLink:visited,
a.blueLink:hover,
a.blueLink:active {
	color: blue;
	/* PS */
	text-decoration: underline;
}
.blackText {
	color: black;
}
ol.questions {
	padding-right: 0px;
	padding-left: 20px;
}
a:link,
a:visited,
a:hover,
a:active {
	color: white; /* PS */
	/* text-decoration: none; */
}
/* PS */
a:link:not(.btn),
a:visited:not(.btn),
a:hover:not(.btn),
a:active:not(.btn) {
	text-decoration: underline;
}
/* PS change to NHS blue, underline */
.blueLinks a:link,
.blueLinks a:visited,
.blueLinks a:hover,
.blueLinks a:active {
	color: #005eb8;
	text-decoration: underline;
}
.titleColour {
	color: black;
}
.pdfViewer {
	background-color: darkgray;
	border-bottom: 20px solid darkgray;
}
.pdfViewer .page {
	/* margin: 0 auto 5px auto; */
	padding: 10px;
	position: relative;
	overflow: hidden;
}
.showMedia {
	width: 100%;
	min-height: 60vh;
}
/* PS change colour */
.showMedia img,
video {
	width: 100%;
	height: 60vh;
}
.showMedia iframe {
	width: 100%;
	height: 60vh;
}
.showMedia audio {
	margin: 20% auto;
	width: 100%;
	transform: scale(1, 1.25);
	box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
	border-radius: 20px;
}
.options a {
	width: 100%;
}
/* PS */
.options p {
	width: 100%;
	position: relative;
	/*   background-color: #e2e3e5; 
  border: 1px solid gray; */
	background-color: #eceeef;
	box-shadow: 3px 3px 3px #dddddd;
	height: 100%;

	border-radius: 5px;
	padding: 10px 15px;
}
/* PS */
/*.options p:hover {
   -webkit-filter: brightness(90%);
   -moz-filter:brightness(90%);
   -ms-filter:brightness(90%);
   -o-filter:brightness(90%);
   filter:brightness(90%);
} */
/* PS */
.options p:after:hover {
	opacity: 1 !important;
}
.categorySelect {
	background-color: #007bff;
	color: white;
}
/* Pixelshrink 2019.11.28
.logoPanel {
  position: absolute;
}*/
.logoPanel img {
	width: 100%;
}
#nhs {
	top: 2%;
	right: 2%;
	width: 80%;
	/* Pixelshrink 2019.11.28 */
	float: right;
}
.info-points ul {
	list-style-type: none;
}
ol.info-points {
	padding: 0;
}
ol.info-points li {
	margin-bottom: 5px;
}
ol.consent-points {
	padding-left: 0;
	padding-right: 0;
}
ol.consent-points input {
	width: 100%;
}
ol.consent-points li {
	margin-bottom: 10px;
	/* PS */
	min-height: 3em;
}
/* PS */
ol.consent-points li.last-li {
	min-height: 1em;
}
ol li {
	padding-left: 5px;
}

.info-points ol,
.info-points ul {
	padding: 0;
}
ul.economics {
	padding: 0;
}
.space-out > [class^="col-"] {
	margin-left: 0;
	margin-right: 67px;
}
.footer > [class^="col-"] {
	padding-left: 0;
	padding-right: 0;
}
.tooltip.in {
	opacity: 1;
}
.slider-track-high {
	background: darkgray;
}
.slider-tick {
	background: darkgray;
}
.slider-handle {
	background: #337ab7;
}
.slider.slider-horizontal {
	width: 100%;
}
.slider .bs-tooltip-top .tooltip-inner {
	position: absolute;
	left: 0;
}
.slider .tooltip-inner {
	max-width: 200px;
}
.accordion {
	--bs-accordion-btn-color: white;
	--bs-accordion-bg: #005eb8;
}
.accordion-item {
	background-color: white;
}
/* Differing screen sizes */
@media (min-width: 576px) {
	#nhs {
		width: 50%;
	}
}

@media (min-width: 768px) {
	#nhs {
		width: 40%;
	}
	.footer p {
		text-align: center;
	}
	.fixedButton button {
		width: 75%;
	}
	/* Pixelshrink 2019.11.28 */
	.neon-logo-desktop {
		display: block;
	}
	.neon-logo-mobile {
		display: none;
	}
	.change-position {
		margin-top: -30px;
	}
	.story-button {
		width: 50% !important;
	}
}

@media (min-width: 992px) {
	#nhs {
		width: 35%;
	}
	/*
   .min-height-full {
	min-height: 33.5vw !IMPORTANT;
  }
  .min-height-half {
	min-height: 10vw !IMPORTANT;
  }*/
}

@media (min-width: 1200px) {
	/* PS  
  #nhs {
    width: 25%;
  }*/
	.fixedButton button {
		width: 45%;
	}
	footer.intervention {
		margin-top: 50px !important;
	}
	/* PS additions 2021.03 */
	.sidebar-p {
		padding-left: 5rem !important;
	}
}

/* PS  */
.trialprocedures-stripe-top {
	width: 100%;
	height: 10px;
	background: transparent url("/public/images/trialprocedures-stripe-top.png")
		repeat-x center scroll;
}
.trialprocedures-stripe-bottom {
	width: 100%;
	height: 10px;
	background: transparent
		url("/public/images/trialprocedures-stripe-bottom.png") repeat-x center
		scroll;
}
h1 {
	margin-bottom: 1.5em;
}
h3 {
	margin-bottom: 0.75em;
	margin-top: 1.25em;
}
.col-form-label {
	font-weight: bold;
}
.btn i.fas {
	padding: 0 0.5em 0 0;
}
.btn i.fas.fa-arrow-right {
	padding: 0 0 0 0.5em;
}
.btn {
	/* PS 
	height: 100%; */
	vertical-align: top;
	box-shadow: 3px 3px 3px #dddddd;
}
/* PS change to NHS blue  */
.btn.btn-primary,
.btn.btn-16,
.btn.btn-17,
.btn.btn-18,
.btn.btn-19,
.progress-bar,
.bg-primary {
	background-color: #005eb8 !important;
	color: #ffffff;
}
ol li label {
	display: inline-block;
	vertical-align: text-top;
	margin-top: -1px;
}
ol.consent-points label.not-full-width {
	vertical-align: middle;
}
ol.consent-points label.not-full-width input {
	width: initial;
	margin-left: 20px;
	vertical-align: top;
}
label select.custom-select {
	margin-top: 10px;
}
label {
	width: 100%;
	margin-bottom: 1rem;
}
.small-gap label {
	margin-bottom: 0.5rem;
}
ul.normal-list {
	list-style: none;
}
ul.normal-list li::before {
	content: "\2022";
	color: #00aeef;
	display: inline-block;
	width: 1em;
	margin-left: -1em;
}

/* PS intervention */
body.intervention {
	margin-right: calc(-100vw + 100%);
	margin-left: 0;
	overflow-x: hidden;
	background-color: #bbc3c7;
}
.content.intervention {
	background-color: #eceeef;
}
.navbar {
	background-color: #622c85;
	padding: 0.5rem 1rem 0 1rem;
	border-radius: 0.3rem;
}
.navbar-dark .navbar-toggler {
	border: none;
	padding: 0 0.75rem 0.25rem 0.75rem;
	color: #ffffff;
	font-weight: bold;
	margin: auto;
}
.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 0.7);
}
.navbar-dark .navbar-nav .nav-link:hover {
	color: rgba(255, 255, 255, 0.85);
}
a.nav-link {
	text-decoration: none !important;
}
.navbar-nav a {
	text-decoration: none !important;
	padding: 0.5rem 1rem;
	color: rgba(255, 255, 255, 0.7);
}
.navbar-collapse {
	transition: 0.5s;
}
.neon-icon {
	opacity: 0.6;
	filter: alpha(opacity=60); /* For IE8 and earlier */
}
.active .nav-link .neon-icon {
	opacity: 1;
	filter: alpha(opacity=100);
}
.nav-link:hover .neon-icon {
	opacity: 0.8;
	filter: alpha(opacity=80);
}

.help-icon {
	font-size: 40px;
	display: inline-block !important;
	text-align: center;
}
.footer-row {
	background-color: #bbc3c7;
}
.footer-row .btn {
	box-shadow: 3px 3px 3px #999999;
	height: auto !important;
}
.footer-row .btn:not(.last-button) {
	margin-right: 16px;
	margin-bottom: 16px;
}
.btn.btn-dark,
.btn.btn-dark:active,
.btn.btn-11,
.btn.btn-11:active,
.btn.btn-12,
.btn.btn-12:active,
.btn.btn-13,
.btn.btn-13:active,
.btn.btn-14,
.btn.btn-14:active,
.btn.btn-15,
.btn.btn-15:active {
	background-color: #63287c !important;
	border-color: #63287c !important;
	color: #ffffff !important;
}
.btn.btn-light,
.btn.btn-light:active,
.btn.btn-01,
.btn.btn-01:active,
.btn.btn-02,
.btn.btn-02:active {
	background-color: #952582 !important;
	border-color: #952582 !important;
	color: #ffffff;
}
a.btn.btn-light:hover,
.btn.btn-01:hover,
.btn.btn-02:hover {
	color: #ffffff !important;
}
.btn.btn-info,
.btn.btn-info:active,
.bg-info,
.btn.btn-03,
.btn.btn-03:active,
.btn.btn-04,
.btn.btn-04:active,
.btn.btn-05,
.btn.btn-05:active,
.btn.btn-06,
.btn.btn-06:active,
.btn.btn-07,
.btn.btn-07:active,
.btn.btn-08,
.btn.btn-08:active,
.btn.btn-09,
.btn.btn-09:active,
.btn.btn-10,
.btn.btn-10:active {
	background-color: #19988d !important;
	border-color: #19988d !important;
	color: #ffffff !important;
}
.btn.btn-warning {
	color: #000000;
}
.btn.btn-buttonLight {
	background-color: #eceeef;
}
.card-header,
.options p {
	cursor: pointer;
}
.card h2 {
	margin-bottom: 0;
}
.card-body ul li,
.card-body ol li {
	margin-bottom: 0.4em;
}
.card-header .fa-chevron-down {
	transition: 0.3s transform ease-in-out;
}
.card-header:not(.collapsed) .fa-chevron-down {
	transform: rotate(180deg);
}
.content {
	flex: 0 1 auto;
}
.nav-tabs .nav-link.active {
	border: none;
}
hr.light {
	border: 1px solid #c2c2c2;
}
.badge.badge-light {
	float: right;
	margin: 0.25rem 0 3em 0;
}
.badge.badge-light.unblock-badge {
	float: right;
	margin: 0.25rem 0 0 0;
}
button#storiesTop,
button#storiesBottom {
	height: 2.5em;
	margin-left: 0.5em;
}
/* 
.min-height-full {
	min-height: 90vw;
}
.min-height-half {
	min-height: 30vw;
}*/
.story-button {
	width: 90%;
}
.very-dark {
	background-color: #622c85;
}
.options button {
	width: 100%;
}
.information h1 {
	font-size: 1.75rem;
}
.information h2 {
	font-size: 1.5rem;
	margin-bottom: 1rem;
}
.information h3 {
	font-size: 1.25rem;
	margin-bottom: 0.75rem;
	margin-top: 1.5rem;
}
ol.economics {
	padding-left: 0px;
}
ol.economics li {
	margin-left: 25px;
}
/* Cell colours for user status */
.whiteCell {
	background-color: white;
}
.greenCell {
	background-color: lightgreen;
}
.redCell {
	background-color: red;
}
.blueCell {
	background-color: blue;
}
.yellowCell {
	background-color: yellow;
}
.redText {
	color: red;
}
/* Short/long text for rating sliders */
.shortText {
	display: none;
}
.vertical-borders {
	border-left: 1px black solid;
	border-right: 1px black solid;
}
.vertical-borders-right {
	border-right: 1px black solid;
}
@media (max-width: 768px) {
	.shortText {
		display: inline;
	}
	.fullText {
		display: none;
	}
}
.containerExtra {
	width: 90%;
	margin: 0 auto;
}
/* PS Additions 2021.03 */
.speech-bubble {
	display: block !important;
}
.speech-bubble-top {
	border-radius: 15px;
}
.speech-bubble-top h2 a {
	font-size: 1.7rem;
	text-decoration: none !important;
}
.speech-bubble-bottom {
	background-image: url("/public/images/speech_bubble_tail_right.png");
	background-position: right top;
	background-repeat: no-repeat;
	background-size: contain;
}
.video-card-grey {
	background-color: #eceeef;
	border: 0px;
	background-image: url("/public/images/bg_arrow_white.png");
	background-position: right center;
	background-repeat: no-repeat;
	background-size: auto 100px;
}

.video-link {
	min-width: 220px;
	text-align: left;
}
.vimeoModal {
	width: 65%;
	max-width: 75%;
	max-height: 90%;
	margin: 30px auto;
}
div[class^="badge-message-"] {
	display: inline;
	margin: 0 15px;
}
.badge-message {
	text-align: center;
}
.badge-message-2 img {
	width: 60px;
	height: 60px;
}
.badge-message-3 {
	font-weight: bold;
}

.badge-on {
	border: 5px solid #ecedef;
	background-color: #ecedef;
	border-radius: 0.25rem;
}
.badge-off {
	border: 5px solid #ecedef;
	border-radius: 0.25rem;
	opacity: 30%;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.badge-on .row,
.badge-off .row {
	max-width: 340px;
	margin-left: auto;
	margin-right: auto;
}
.badge-image {
	padding-right: 10px;
}
.badge-image img {
	width: 100%;
	height: auto;
}
.badge-desc {
	padding-left: 10px;
	margin-bottom: 0;
	margin-top: auto;
}
.badge-title {
	display: block;
	margin-bottom: 10px;
}
/* TEMP FIX!! MIGRATING TO BOOTSTRAP 5 CAUSES SOME ISSUES!!! */
@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
}

@media (min-width: 1400px) {
	.container {
		max-width: 1320px;
	}
}

@media (max-width: 1200px) and (min-width: 768px) {
	.sidebar-p {
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
}
@media (max-width: 576px) {
	.video-card-grey {
		background-image: none;
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.badge-on.container,
	.badge-off.container {
		max-width: 400px;
	}
}

@media (min-width: 992px) {
	#nhs {
		width: 35%;
	}
	/*
   .min-height-full {
	min-height: 33.5vw !IMPORTANT;
  }
  .min-height-half {
	min-height: 10vw !IMPORTANT;
  }*/
}

@media (min-width: 1200px) {
	/* PS  
  #nhs {
    width: 25%;
  }*/
	.fixedButton button {
		width: 45%;
	}
	footer.intervention {
		margin-top: 50px !important;
	}
	/* PS additions 2021.03 */
	.sidebar-p {
		padding-left: 5rem !important;
	}
}

@media (max-width: 768px) {
	.shortText {
		display: inline;
	}
	.fullText {
		display: none;
	}
	/* PS different logos for mobile & desktop */
	.neon-logo-desktop {
		display: none;
	}
	.neon-logo-mobile {
		display: block;
	}
	.change-position {
		margin-top: 20px;
	}

	div[class^="badge-message-"] {
		display: block;
		margin: 10px 0;
	}
}
